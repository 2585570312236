export const SHOW_HOME = "8bz5";

export const VIEW = {
  LIST: 'list',
  GRID: 'grid'
}

export const oliviaAltNavLinks = [
  { href: '/olivia#projects', text: 'Projects' },
  { href: '/olivia#experience', text: 'Experience' },
  { href: '/olivia#education', text: 'Education' },
]

export const ivanAltNavLinks = [
  { href: '/ivan#projects', text: 'Projects' },
]
