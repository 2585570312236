import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { SHOW_HOME } from "../constants";
import { getHashPresent, removeHash, reload } from "../utils";

export default ({ pageTitle }) => {
  // Replace &nbsp; with a space
  const formattedTitle = pageTitle ? pageTitle.replace(/&nbsp;/g, " ") : null;

  useEffect(() => {
    const hash = getHashPresent(SHOW_HOME);
    if (hash) {
      localStorage.setItem(SHOW_HOME, true);
      removeHash();
      reload();
    }
  }, []);

  return (
    <Helmet
      title={
        formattedTitle ? `${formattedTitle} | Ruiz & Knott` : `Ruiz & Knott`
      }
    >
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#031939" />
      <meta name="msapplication-TileColor" content="#031939" />
      <meta name="theme-color" content="#031939" />
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
  );
};
