export const shortAuthor = (author) => {
  switch (author) {
    case "Ivan Ruiz-Knott":
      return "Ivan";
    case "Olivia Ruiz-Knott":
      return "Livvy";
    default:
      return author;
  }
};

// given a number and the desired length, pad the number with leading zeros
export const pad = (num, length) => {
  const s = num + "";
  return s.padStart(length, "0");
};

// given a start Date, end Date, and total number of days, calculate the true number of months passed and remaining days
const calculateMonths = (startDate, endDate, totalDays) => {
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  // this is where we do calculations for months
  let remainingDays = totalDays;
  let totalMonths = 0;

  for (let year = startYear; year <= endYear; year++) {
    const startMonthIndex = year === startYear ? startMonth : 0;
    const endMonthIndex = year === endYear ? endMonth : 11;

    for (let month = startMonthIndex; month <= endMonthIndex; month++) {
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      if (remainingDays >= daysInMonth) {
        remainingDays -= daysInMonth;
        totalMonths++;
      } else {
        break; // No need to check further months
      }
    }
  }

  return {
    totalMonths,
    remainingDays,
  };
};

// the milliseconds remaining after dividing out the seconds
export const getRemainingMilliseconds = (ms) => {
  return ms % 1000;
};

// the seconds remaining after dividing out the minutes
export const getRemainingSeconds = (ms) => {
  return Math.floor((ms / 1000) % 60);
};

// the minutes remaining after dividing out the hours
export const getRemainingMinutes = (ms) => {
  return Math.floor((ms / (1000 * 60)) % 60);
};

// the hours remaining after dividing out the days
export const getRemainingHours = (ms) => {
  return Math.floor((ms / (1000 * 60 * 60)) % 24);
};

// the months remaining after dividing out the years (given months)
export const getRemainingMonths = (mo) => {
  return mo % 12;
};

// the total number of days represented by the milliseconds
export const getTotalDays = (ms) => {
  return Math.floor(ms / (1000 * 60 * 60 * 24));
};

// the total number of years represented by the months
export const getTotalYears = (mo) => {
  return Math.floor(mo / 12);
};

export const getTimeBetween = (startTimestamp, endTimestamp) => {
  const diff = endTimestamp - startTimestamp;

  // milliseconds, seconds, minutes, hours, and total days can be calculated directly with math

  const ms = getRemainingMilliseconds(diff);
  const s = pad(getRemainingSeconds(diff), 2);
  const m = pad(getRemainingMinutes(diff), 2);
  const h = pad(getRemainingHours(diff), 2);
  const totalDays = getTotalDays(diff);

  // months and remaining days require knowledge of how many days are in each month,
  // so we use a helper function to calculate them

  const {
    totalMonths, // the total number of months in the given period
    remainingDays, // the days remaining after dividing out the months
  } = calculateMonths(
    new Date(startTimestamp),
    new Date(endTimestamp),
    totalDays
  );

  const d = pad(remainingDays, 2);
  const mo = pad(getRemainingMonths(totalMonths), 2);
  const y = pad(getTotalYears(totalMonths), 2);

  return { ms, s, m, h, d, mo, y };
};

export const getHashQueryParam = (variable) => {
  const url = new URL(document.location);
  const searchParams = new URLSearchParams(url.hash);
  for (let [key, value] of searchParams) {
    if (key === variable) return value;
  }
  return null;
};

export const getHashPresent = (variable) => {
  const hash = new URL(document.location).hash.substring(1);
  return hash === variable;
};

export const removeHash = () => {
  if (typeof window === "undefined") return;
  window.history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  );
};

export const reload = () => {
  if (typeof window === "undefined") return;
  window.location.reload();
};
