import React from "react";

export default () => (
  <footer className="bg-theme2-bg-1 font-sans p-4">
    <div className="md:max-w-5xl md:mx-auto">
      <div className="font-bold text-theme2-accent text-xs tracking-wider uppercase">
        Colophon
      </div>
      <div className="font-extralight leading-5 my-4 text-lg text-theme2-primary max-w-lg">
        Site designed and developed in-house by Ivan & Livvy. Project managed
        with Notion and Slack. Screens designed in Figma. Custom fonts “Suits”,
        “RK Sans”, “RK Serif”, and “RK Mono” designed in Glyphs. Hosted with
        Netlify, managed with Contentful, built with Gatsby, styled with
        Tailwind. A work in progress. Site version 1.1.
      </div>
      <div className="font-extralight	text-theme2-accent text-sm">
        © 2014–2024 Ruiz & Knott
      </div>
    </div>
  </footer>
);
