import React from "react";
import Container from "./container";
import Navigation from "./navigation";
import Footer from "./footer";

const Template = ({
  navigation,
  killPadding = false,
  killFooter = false,
  background,
  children,
}) => {
  const renderNavigation = () => {
    if (navigation === false) return null;
    if (navigation) return navigation;
    return <Navigation />;
  };

  return (
    <Container background={background}>
      <div className={killPadding ? "" : "md:mb-20"}>
        {renderNavigation()}
        {children}
      </div>
      {killFooter ? "" : <Footer />}
    </Container>
  );
};

export default Template;
